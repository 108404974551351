import React, { Component } from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import styled from 'styled-components';

import '../globalStyles.css';
import Layout from '../components/Layout';

const ConfigFileCreatorHeader = styled.header`
  text-align: center;
  margin-bottom: 60px;

  & h1 {
    font-size: 50px;
    text-align: center;
    margin-top: 70px;
    margin-bottom: 0;
  }

  & h2 {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    margin-top: 0;
    white-space: pre-wrap;
  }
`;

const ConfigFileContainer = styled.div`

  & h3 {
    margin-top: 100px;
    text-align: center;
  }
`;

const ConfigFileForm = styled.form`

  width: 70%;
  margin: auto;

  & label {
    font-weight: bold;
  }

  & input, select {
    width: 300px;
  }

  & input[type=number] {
    width: 80px;
  }
`;

const CheckboxToggle = styled.label`

  margin-right: 10px;
  position: relative;
  display: inline-block;
  width: 45px;
  height: 22px;

  & input {
    display:none;
  }

  & .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-grey);
    transition: .4s;
  }

  & .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 4px;
    background-color: var(--color-white);
    transition: .4s;
  }

  & input:checked + .slider {
    background-color: var(--color-teal);
  }

  & input:focus + .slider {
    box-shadow: 0 0 1px var(--color-teal);
  }

  & input:checked + .slider:before {
    transform: translateX(22px);
  }

  & .slider.round {
    border-radius: 34px;
  }

  & .slider.round:before {
    border-radius: 50%;
  }

`;

const Helptext = styled.div`
  
  white-space: pre-wrap;

  & .centered {
    text-align: center;
    margin-bottom: 60px;
  }

`;

const GridContainer = styled.div`

  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 25px;
  row-gap: 50px;
  align-items: center;
  margin-bottom: 50px;

  & > :nth-child(odd) {
    justify-self: end;
  }

  p {
    margin-bottom: 0
  }
`;

const ErrorText = styled.span`
  font-size: 15px;
  font-weight: bold;
  color: var(--color-magenta);
  margin-left: 10px;
`;

const ErrorTextDomain = styled.p`
  font-weight: bold;
  color: var(--color-magenta);
`;

const SuccessTextDomain = styled.p`
  font-weight: bold;
  color: green;
`;

export default class ConfigFileCreator extends Component {
  static propTypes = {
    data: PropTypes.object,
    pageContext: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      domain: '',
      beOffline: false,
      forceOffline: false,
      autoUpdate: true,
      hideMenuBar: true,
      electronKioskMode: true,
      email: '',
      password: '',
      autostartPresentationId: '',
      autosyncActivated: false,
      isEnabled: true,
      isKeyboardDisabled: false,
      isPinEnabled: true,
      pin: 1324,
      pinAppearance: 'END_PRESENTATION',
      isAutoplayEnabled: true,
      autoPlayTime: 50,
      isResetEnabled: true,
      resetTime: 120,
      isSwipeDisabled: true,
      domainValidationSuccess: false,
      domainValidationError: false,
      credentialsValidationError: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  validateDomain = (domain) => {
    this.setState({ domainValidationSuccess: false, domainValidationError: false });
    fetch(`https://${domain}/api/config`)
      .then((response) => response.json())
      .then((responseData) => {
        this.setState({ domainValidationSuccess: !!responseData.presono, domainValidationError: !responseData.presono });
      })
      .catch((error) => {
        console.error('Error:', error);
        this.setState({ domainValidationSuccess: false, domainValidationError: true });
      });
  };

  validateCredentials = () => {
    const { email, password, domain } = this.state;
    this.setState({ credentialsValidationError: false });

    fetch(`https://${domain}/api/token`, {
      method: 'POST',
      body: JSON.stringify({
        email,
        password,
        grant_type: 'password',
        scope: '',
      }),
    })
      .then((response) => response.json())
      .then((responseData) => {
        console.log('SUCCESS:', responseData);
        this.setState({ credentialsValidationError: !responseData.access_token });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  checkCredentials = () => {
    const { email, password } = this.state;

    if (password !== '' && email.trim() !== '') {
      this.validateCredentials();
    } else {
      this.setState({ credentialsValidationError: false });
    }
  };

  render() {
    const { data, pageContext } = this.props;
    const content = data?.mdx?.frontmatter || 'CONTENT NOT FOUND';

    let canonicalLinkPrefix;

    if (pageContext.locale === 'en') {
      canonicalLinkPrefix = 'https://presono.com/en';
    } else {
      canonicalLinkPrefix = 'https://presono.com';
    }

    console.log('DATA-CFC', data);

    const {
      domain,
      beOffline,
      forceOffline,
      autoUpdate,
      hideMenuBar,
      electronKioskMode,
      email,
      password,
      autostartPresentationId,
      autosyncActivated,
      isEnabled,
      isKeyboardDisabled,
      isPinEnabled,
      pin,
      pinAppearance,
      isAutoplayEnabled,
      autoPlayTime,
      isResetEnabled,
      resetTime,
      isSwipeDisabled,
      domainValidationSuccess,
      domainValidationError,
      credentialsValidationError,
    } = this.state;

    console.log('DOMAIN', domain);
    // console.log('BE-OFFLINE', beOffline);
    // console.log('FORCE-OFFLINE', forceOffline);
    // console.log('AUTO-UPDATE', autoUpdate);
    // console.log('AUTOPLAY-TIME', autoPlayTime);
    // console.log('PIN_APPEARANCE', pinAppearance);
    console.log('EMAIL', email);
    console.log('PASSWORD', password);

    return (
      <Layout currentPath={pageContext.originalPath}>
        <Helmet>
          <title>{`${data?.mdx?.frontmatter?.title} | presono`}</title>
          <meta
            name="description"
            content={data?.mdx?.frontmatter?.snippetTextConfigFileCreator}
          />

          <link rel="canonical" href={canonicalLinkPrefix + pageContext.originalPath} />

          <meta property="og:title" content={data?.mdx?.frontmatter?.title} />
          <meta property="og:description" content={data?.mdx?.frontmatter?.snippetTextConfigFileCreator} />
          <meta name="twitter:title" content={data?.mdx?.frontmatter?.title} />
          <meta name="twitter:description" content={data?.mdx?.frontmatter?.snippetTextConfigFileCreator} />
        </Helmet>
        <div tabIndex="-1">
          <ConfigFileCreatorHeader>
            <h1>
              {content?.headlineConfigFileCreator}
            </h1>
            <h2>
              <MDXRenderer>{content?.subtextConfigFileCreator}</MDXRenderer>
            </h2>
          </ConfigFileCreatorHeader>
          <ConfigFileContainer>
            <ConfigFileForm>
              <GridContainer>

                <input
                  type="text"
                  value={domain}
                  onChange={this.handleInputChange}
                  // eslint-disable-next-line max-len
                  onBlur={(e) => {
                    let domainToCheck = domain;
                    if (domain.trim() !== '' && !domain.includes('.')) {
                      domainToCheck = `${e.target.value}.presono.com`;
                      this.setState({ domain: domainToCheck });
                    }
                    if (domainToCheck.trim() === '') domainToCheck = 'my.presono.com';
                    this.validateDomain(domainToCheck);
                  }}
                  name="domain"
                  placeholder="my.presono.com"
                />
                <div>
                  <label htmlFor="domain">
                    {content?.domainLabel}
                  </label>
                  <Helptext>
                    <MDXRenderer>{content?.domainHelptext}</MDXRenderer>
                  </Helptext>
                  {domainValidationError && (<ErrorTextDomain>{content?.errorTextDomainValidation}</ErrorTextDomain>)}
                  {domainValidationSuccess && (<SuccessTextDomain>{content?.successTextDomainValidation}</SuccessTextDomain>)}
                </div>
                <CheckboxToggle>
                  <input
                    type="checkbox"
                    checked={beOffline || forceOffline}
                    onChange={this.handleInputChange}
                    name="beOffline"
                    disabled={forceOffline}
                  />
                  <span className="slider round" />
                </CheckboxToggle>
                <div>
                  <label htmlFor="beOffline">
                    {content?.beOfflineLabel}
                    {forceOffline ? <ErrorText>{content?.errorTextOfflineMode}</ErrorText> : ''}
                  </label>
                  <Helptext>
                    <MDXRenderer>{content?.beOfflineHelptext}</MDXRenderer>
                  </Helptext>
                </div>

                <CheckboxToggle>
                  <input
                    type="checkbox"
                    checked={forceOffline}
                    onChange={this.handleInputChange}
                    name="forceOffline"
                  />
                  <span className="slider round" />
                </CheckboxToggle>
                <div>
                  <label htmlFor="forceOffline">
                    {content?.forceOfflineLabel}
                  </label>
                  <Helptext>
                    <MDXRenderer>{content?.forceOfflineHelptext}</MDXRenderer>
                  </Helptext>
                </div>

                <CheckboxToggle>
                  <input
                    type="checkbox"
                    checked={autoUpdate}
                    onChange={this.handleInputChange}
                    name="autoUpdate"
                  />
                  <span className="slider round" />
                </CheckboxToggle>
                <div>
                  <label htmlFor="autoUpdate">
                    {content?.autoUpdateLabel}
                  </label>
                  <Helptext>
                    <MDXRenderer>{content?.autoUpdateHelptext}</MDXRenderer>
                  </Helptext>
                </div>

                <CheckboxToggle>
                  <input
                    type="checkbox"
                    checked={hideMenuBar}
                    onChange={this.handleInputChange}
                    name="hideMenuBar"
                  />
                  <span className="slider round" />
                </CheckboxToggle>
                <div>
                  <label htmlFor="hideMenuBar">
                    {content?.hideMenuBarLabel}
                  </label>
                  <Helptext>
                    <MDXRenderer>{content?.hideMenuBarHelptext}</MDXRenderer>
                  </Helptext>
                </div>

                <CheckboxToggle>
                  <input
                    type="checkbox"
                    checked={electronKioskMode}
                    onChange={this.handleInputChange}
                    name="electronKioskMode"
                  />
                  <span className="slider round" />
                </CheckboxToggle>
                <div>
                  <label htmlFor="electronKioskMode">
                    {content?.electronKioskModeLabel}
                  </label>
                  <Helptext>
                    <MDXRenderer>{content?.electronKioskModeHelptext}</MDXRenderer>
                  </Helptext>
                </div>

                <input
                  type="email"
                  value={email}
                  onChange={this.handleInputChange}
                  onBlur={(this.checkCredentials)}
                  name="email"
                />
                <div>
                  <label htmlFor="email">
                    {content?.emailLabel}
                  </label>
                  <Helptext>
                    <MDXRenderer>{content?.emailHelptext}</MDXRenderer>
                  </Helptext>
                </div>

                <input
                  type="password"
                  value={password}
                  onChange={this.handleInputChange}
                  onBlur={(this.checkCredentials)}
                  name="password"
                />
                <div>
                  <label htmlFor="password">
                    {content?.passwordLabel}
                  </label>
                  <Helptext>
                    <MDXRenderer>{content?.passwordHelptext}</MDXRenderer>
                  </Helptext>
                  {credentialsValidationError && (<ErrorTextDomain>{content?.errorTextCredentialsValidation}</ErrorTextDomain>)}
                </div>

                <input
                  type="text"
                  value={autostartPresentationId}
                  onChange={this.handleInputChange}
                  name="autostartPresentationId"
                />
                <div>
                  <label htmlFor="autostartPresentationId">
                    {content?.autostartPresentationIdLabel}
                  </label>
                  <Helptext>
                    <MDXRenderer>{content?.autostartPresentationIdHelptext}</MDXRenderer>
                  </Helptext>
                </div>

                <CheckboxToggle>
                  <input
                    type="checkbox"
                    checked={autosyncActivated}
                    onChange={this.handleInputChange}
                    name="autosyncActivated"
                  />
                  <span className="slider round" />
                </CheckboxToggle>
                <div>
                  <label htmlFor="autosyncActivated">
                    {content?.autosyncActivatedLabel}
                  </label>
                  <Helptext>
                    <MDXRenderer>{content?.autosyncActivatedHelptext}</MDXRenderer>
                  </Helptext>
                </div>

              </GridContainer>

              { autostartPresentationId.trim() !== ''
                && (
                  <>
                    <h3>{content?.kioskModeLabel}</h3>
                    <Helptext>
                      <MDXRenderer className="centered">{content?.kioskModeHelptext}</MDXRenderer>
                    </Helptext>

                    <GridContainer>

                      <CheckboxToggle>
                        <input
                          type="checkbox"
                          checked={isEnabled}
                          onChange={this.handleInputChange}
                          name="isEnabled"
                        />
                        <span className="slider round" />
                      </CheckboxToggle>
                      <div>
                        <label htmlFor="kioskModeIsEnabled">
                          {content?.kioskModeIsEnabledLabel}
                        </label>
                        <Helptext>
                          <MDXRenderer>{content?.kioskModeIsEnabledHelptext}</MDXRenderer>
                        </Helptext>
                      </div>
                    </GridContainer>

                    { isEnabled
                      && (
                        <GridContainer>
                          <CheckboxToggle>
                            <input
                              type="checkbox"
                              checked={isKeyboardDisabled}
                              onChange={this.handleInputChange}
                              name="isKeyboardDisabled"
                            />
                            <span className="slider round" />
                          </CheckboxToggle>
                          <div>
                            <label htmlFor="kioskModeIsKeyboardDisabled">
                              {content?.kioskModeIsKeyboardDisabledLabel}
                            </label>
                            <Helptext>
                              <MDXRenderer>{content?.kioskModeIsKeyboardDisabledHelptext}</MDXRenderer>
                            </Helptext>
                          </div>

                          <CheckboxToggle>
                            <input
                              type="checkbox"
                              checked={isPinEnabled}
                              onChange={this.handleInputChange}
                              name="isPinEnabled"
                            />
                            <span className="slider round" />
                          </CheckboxToggle>
                          <div>
                            <label htmlFor="kioskModeIsPinEnabled">
                              {content?.kioskModeIsPinEnabledLabel}
                            </label>
                            <Helptext>
                              <MDXRenderer>{content?.kioskModeIsPinEnabledHelptext}</MDXRenderer>
                            </Helptext>
                          </div>

                          { isPinEnabled
                            && (
                              <>
                                <input
                                  type="number"
                                  value={pin}
                                  onChange={this.handleInputChange}
                                  name="pin"
                                />
                                <div>
                                  <label htmlFor="kioskModePinLabel">
                                    {content?.kioskModePinLabel}
                                  </label>
                                  <Helptext>
                                    <MDXRenderer>{content?.kioskModePinHelptext}</MDXRenderer>
                                  </Helptext>
                                </div>

                                <select
                                  value={pinAppearance}
                                  name="pinAppearance"
                                  onChange={this.handleInputChange}
                                >
                                  <option value="END_PRESENTATION">{content?.pinAppearanceOption1}</option>
                                  <option value="ENTER_MENU">{content?.pinAppearanceOption2}</option>
                                </select>
                                <div>
                                  <label htmlFor="kioskModePinAppearance">
                                    {content?.kioskModePinAppearanceLabel}
                                  </label>
                                  <Helptext>
                                    <MDXRenderer>{content?.kioskModePinAppearanceHelptext}</MDXRenderer>
                                  </Helptext>
                                </div>
                              </>
                            )}

                          <CheckboxToggle>
                            <input
                              type="checkbox"
                              checked={isAutoplayEnabled}
                              onChange={this.handleInputChange}
                              name="isAutoplayEnabled"
                            />
                            <span className="slider round" />
                          </CheckboxToggle>
                          <div>
                            <label htmlFor="kioskModeIsAutoplayEnabled">
                              {content?.kioskModeIsAutoplayEnabledLabel}
                            </label>
                            <Helptext>
                              <MDXRenderer>{content?.kioskModeIsAutoplayEnabledHelptext}</MDXRenderer>
                            </Helptext>
                          </div>

                          { isAutoplayEnabled
                            && (
                              <>
                                <input
                                  type="number"
                                  value={autoPlayTime}
                                  onChange={this.handleInputChange}
                                  name="autoPlayTime"
                                />
                                <div>
                                  <label htmlFor="kioskModeAutoPlayTime">
                                    {content?.kioskModeAutoPlayTimeLabel}
                                  </label>
                                  <Helptext>
                                    <MDXRenderer>{content?.kioskModeAutoPlayTimeHelptext}</MDXRenderer>
                                  </Helptext>
                                </div>
                              </>
                            )}

                          <CheckboxToggle>
                            <input
                              type="checkbox"
                              checked={isResetEnabled}
                              onChange={this.handleInputChange}
                              name="isResetEnabled"
                            />
                            <span className="slider round" />
                          </CheckboxToggle>
                          <div>
                            <label htmlFor="kioskModeIsResetEnabled">
                              {content?.kioskModeIsResetEnabledLabel}
                            </label>
                            <Helptext>
                              <MDXRenderer>{content?.kioskModeIsResetEnabledHelptext}</MDXRenderer>
                            </Helptext>
                          </div>

                          { isResetEnabled
                            && (
                              <>
                                <input
                                  type="number"
                                  value={resetTime}
                                  onChange={this.handleInputChange}
                                  name="resetTime"
                                />
                                <div>
                                  <label htmlFor="kioskModeResetTime">
                                    {content?.kioskModeResetTimeLabel}
                                  </label>
                                  <Helptext>
                                    <MDXRenderer>{content?.kioskModeResetTimeHelptext}</MDXRenderer>
                                  </Helptext>
                                </div>
                              </>
                            )}

                          <CheckboxToggle>
                            <input
                              type="checkbox"
                              checked={isSwipeDisabled}
                              onChange={this.handleInputChange}
                              name="isSwipeDisabled"
                            />
                            <span className="slider round" />
                          </CheckboxToggle>
                          <div>
                            <label htmlFor="kioskModeIsSwipeDisabled">
                              {content?.kioskModeIsSwipeDisabledLabel}
                            </label>
                            <Helptext>
                              <MDXRenderer>{content?.kioskModeIsSwipeDisabledHelptext}</MDXRenderer>
                            </Helptext>
                          </div>
                        </GridContainer>
                      )}
                  </>
                )}
            </ConfigFileForm>
          </ConfigFileContainer>
        </div>
      </Layout>
    );
  }
}

export const query = graphql`
query ConfigFileCreatorQuery($locale: String!) {
  mdx(
    fields: { locale: { eq: $locale } },
    frontmatter: { layout: { eq: "configFileCreator" } }
  ) {
    frontmatter {
      title
      snippetTextConfigFileCreator
      headlineConfigFileCreator
      subtextConfigFileCreator
      domainLabel
      domainHelptext
      beOfflineLabel
      beOfflineHelptext
      forceOfflineLabel
      forceOfflineHelptext
      autoUpdateLabel
      autoUpdateHelptext
      hideMenuBarLabel
      hideMenuBarHelptext
      electronKioskModeLabel
      electronKioskModeHelptext
      emailLabel
      emailHelptext
      passwordLabel
      passwordHelptext
      autostartPresentationIdLabel
      autostartPresentationIdHelptext
      autosyncActivatedLabel
      autosyncActivatedHelptext
      kioskModeLabel
      kioskModeHelptext
      kioskModeIsEnabledLabel
      kioskModeIsEnabledHelptext
      kioskModeIsKeyboardDisabledLabel
      kioskModeIsKeyboardDisabledHelptext
      kioskModeIsPinEnabledLabel
      kioskModeIsPinEnabledHelptext
      kioskModePinLabel
      kioskModePinHelptext
      kioskModePinAppearanceLabel
      kioskModePinAppearanceHelptext
      pinAppearanceOption1
      pinAppearanceOption2
      kioskModeIsAutoplayEnabledLabel
      kioskModeIsAutoplayEnabledHelptext
      kioskModeAutoPlayTimeLabel
      kioskModeAutoPlayTimeHelptext
      kioskModeIsResetEnabledLabel
      kioskModeIsResetEnabledHelptext
      kioskModeResetTimeLabel
      kioskModeResetTimeHelptext
      kioskModeIsSwipeDisabledLabel
      kioskModeIsSwipeDisabledHelptext
      errorTextOfflineMode
      errorTextDomainValidation
      successTextDomainValidation
      errorTextCredentialsValidation
    }
  }
}
`;
